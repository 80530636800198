<template>

    <div class="ar-experience" :class="{'tablet' : tablet}">

        <ar-terrain-mountain @handle::modal="handleModal($event,name)"
            @show::modal="showModal($event,flag)"
        ></ar-terrain-mountain>

        <transition name="fade" mode="out-in"> 
            <modal class="modal" @show::modal="showModal($event,flag)" :tablet="tablet" v-if="modalOpen">
                <img src="@/assets/images/ar-terrain/img-basecamp-icon.webp">
                <h2>{{ data[pos].title }}</h2>
                <div class="divider"></div>
                <h3>{{ data[pos].m }} <span>m </span>/ {{ data[pos].ft }}<span> ft</span></h3>
                <div class="fold">
                    <img class="photo" :src=data[pos].img>
                    <p>{{ data[pos].info }}</p>
                </div>
            </modal>
        </transition>
        
    </div>

</template>


<script>

import ArTerrainMountain from '@/components/ar-terrain/ArTerrainMountain'
import Modal from '@/components/ar-terrain/Modal'

export default {

    name: 'ArExperience',

    props: ['tablet'],

    components: {
        ArTerrainMountain,
        Modal
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            pos: 0,
            modalOpen: false,
            name: '',
            flag: false,
            data: [
                {
                    title: 'Base Camp',
                    ft: '17,291',
                    m: '5,270',
                    hours: '',
                    climb: '',
                    img: require('../../assets/images/ar-terrain/img-modal-photo1.webp'),
                    info: 'Most people climb Everest’s southern side, in Nepal, which starts here. Around 1,300 people call this pop-up city home at the height of the climbing season.'
                },
                {
                    title: 'Camp I',
                    ft: '19,840',
                    m: '6,047',
                    hours: '4-6 Hour',
                    climb: 'Climb From Base Camp',
                    img: require('../../assets/images/ar-terrain/img-modal-photo2.webp'),
                    info: 'Here, you face the most dangerous part of the south climb. You must cross the Khumbu Icefall—a river of shifting ice. Sometimes the ice moves as much as a meter (three feet) a day. You will cross deep crevasses and towering ice seracs.'
                },
                {
                    title: 'Camp II',
                    ft: '19,840',
                    m: '6,047',
                    hours: '2-3 Hour',
                    climb: 'Climb From Camp I',
                    img: require('../../assets/images/ar-terrain/img-modal-photo3.webp'),
                    info: 'You must cross the Western Cwm—a broad glacial valley that ends at the foot of the Lhotse Face. Cwm (pronounced KOOM) is Welsh for “valley.” Here, the threat of avalanche is high.'
                },
                {
                    title: 'Camp III',
                    ft: '23,224',
                    m: '7,079',
                    hours: '3-6 Hour',
                    climb: 'Climb From Camp II',
                    img: require('../../assets/images/ar-terrain/img-modal-photo4.webp'),
                    info: 'You’ll need fixed ropes to scale the steep Lhotse Face. The angles can range from 40 to 50 degrees. You’ll begin to feel the effects of the high altitude here.'
                },
                {
                    title: 'Camp IV',
                    ft: '25,909',
                    m: '7,897',
                    hours: '3-6 Hour',
                    climb: 'Climb From Camp III',
                    img: require('../../assets/images/ar-terrain/img-modal-photo5.webp'),
                    info: 'Welcome to the Death Zone. This is the staging area for your summit bid. It is covered with loose rock and swept by hurricane-force winds. Most climbers will now use bottled oxygen. Factor in 8-13 hours to reach the summit. Many climbers start the night before to reach the summit by morning.'
                },
                {
                    title: 'Summit',
                    ft: '29,032',
                    m: '8,849',
                    hours: '8-13 Hour',
                    climb: 'Climb From Camp IV',
                    img: require('../../assets/images/ar-terrain/img-modal-photo6.webp'),
                    info: 'Congratulations! You have reached Earth’s highest point - a small chunk of rock about the size of two Ping-Pong tables. Factor in 4 - 6 hours for the return trip to Camp IV. Remember: Most accidents happen on the descent. Watch your step. Watch your oxygen levels.'
                }
                
            ]
        }
    },

    computed: {

    },
    
    created() {
        window.history.pushState({}, '', '/ar-terrain');
    },
    
    mounted() {

    },
    
    methods: {
        /**
         * @function handleModal
         * @description
         *
         */
        handleModal(name) {
            this.name = name
            this.showModal(true)
            if(this.name === 'Basecamp' || this.name === 'Basecamp_Icon') {
                this.pos = 0
            }
            if(this.name === 'Camp_1' || this.name === 'Basecamp_Icon001') {
                this.pos = 1
            }
            if(this.name === 'Camp_2' || this.name === 'Basecamp_Icon002') {
                this.pos = 2
            }
            if(this.name === 'Camp_3' || this.name === 'Basecamp_Icon003') {
                this.pos = 3
            }
            if(this.name === 'Camp_4' || this.name === 'Basecamp_Icon004') {
                this.pos = 4
            }
            if(this.name === 'Summit' || this.name === 'Basecamp_Icon005') {
                this.pos = 5
            }
        },
        /**
         * @function showModal
         * @description
         *
         */
        showModal(flag) {
            this.flag = flag
            this.modalOpen = this.flag
        }
    }
    
}

</script>


<style lang="scss" scoped>

.ar-experience {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.tablet {
        
        & .photo {
            width: 600px;
        }

        & img {
            width: 50px;
            margin: 25px 0 0 0;
        }

        & p {
            font-size: 20px;
            line-height: 32px;
            margin: 15px 0 95px 0;
            padding: 0 75px;
        }

        & h2 {
            font-size: 34px;
            margin: 10px 0 0;
        }

        & h3 {
            font-size: 22px;
            line-height: 22px;
            margin: 15px 0;

            & span {
                font-size: 18px;
            }
        }

        & .divider {
            width: 350px;
            margin-top: 15px;
        }
    }

    & .modal.col {
        
        & .fold {
            display: none;
        }
    }

    & .modal.exp {
        
        & .fold {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    & .photo {
        width: 288px;
        
        @media only screen
            and (min-device-width : 375px)
            and (max-device-width : 667px)
            and (orientation : portrait)
            and (-webkit-device-pixel-ratio: 2) { 
                width: 210px;
         }
        
    }

    & p {
        color: #4d5384;
        font-size: 14px;
        line-height: 22px;
        font-weight: $font-weight-medium;
        text-align: center;
        margin: 10px 0 55px 0;
        padding: 0 25px;

        @media only screen
            and (min-device-width : 375px)
            and (max-device-width : 667px)
            and (orientation : portrait)
            and (-webkit-device-pixel-ratio: 2) { 
                font-size: 11px;
                line-height: 20px;
         }
    }

    & h2 {
        text-transform: uppercase;
        color: #0f005d;
        font-size: 24px;
        font-weight: $font-weight-extrabold;
        margin: 5px 0 0;
    }

    & h3 {
        color: #00108f;
        font-size: 15px;
        font-weight: $font-weight-bold;
        line-height: 20px;
        margin: 9px 0;
        text-transform: uppercase;

        & span {
            font-size: 12px;
        }
    }

    & img {
        width: 30px;
        margin: 17px 0 0 0;
    }

    & .divider {
        background: #4d5384;
        width: 200px;
        height: 1px;
        margin-top: 10px;
    }
}

</style>