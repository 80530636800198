<template>

    <div class="modal" :class="getExpanded">
        <div class="stroke"></div>
        <div class="fade"></div>
        <div class="inner">
            <slot></slot>
        </div>
        <button class="btn_close" @click="toggleModalOpen">
            <div class="rec"></div>
            <div class="rec"></div>
        </button>
        <button @click="toggleModalExpand" :class="getExpanded"></button>
    </div>

</template>


<script>

export default {

    name: 'Modal',

    props: ['tablet'],

    data() {
        return {
            open: true,
            expanded: false
        }
    },

    computed: {
        getExpanded: function() {
            let el = !this.expanded ? 'col' : 'exp'
            if(this.tablet) {
                el += ' tablet'
            }
            return el
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        /**
         * @function toggleModalOpen
         * @description
         * 
         */
        toggleModalOpen: function() {
            this.open = !this.open
            this.$emit('show::modal')
        },
        /**
         * @function toggleModalExpand
         * @description
         * 
         */
        toggleModalExpand: function() {
            this.expanded = !this.expanded
        }
    }

}

</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        bottom: 0;
        width: 90%;
        height: 168px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1001;

        &.tablet {
            height: 260px;

            &.exp {

                & .stroke {
                    height: 99.5%;
                }
            }
            
            & .stroke {
                width: 98.75%;
            }

            & button {
                bottom: 15px;
                width: 291px;
                height: 50px;
                margin: 7px 0;
                background-size: 291px 100px;

                &.exp {
                    background-position: 0 -50px;
                }
            }

            & .btn_close {
                width: 48px;
            }
        }

        &.exp {
            height: auto;

            & .stroke {
                height: 99.3%;
            }
        }

        &::before {
            content: '';
            position: absolute;
            background: white;
            width: 100%;
            height: 100%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            opacity: .9;
        }

        & .inner {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .stroke {
            position: absolute;
            bottom: 0;
            border: 2px solid #f8c901;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            width: 98%;
            height: 98%;
        }

        & .fade {
            background: linear-gradient(to top,  rgba(255,255,255,1) 15%,rgba(255,255,255,0) 100%);
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70px;
            display: block;
        }

        & button {
            position: absolute;
            bottom: 2px;
            background-image: url('../../assets/images/ar-terrain/img-button-explore.webp');
            background-size: 194px 66px;
            border: none;
            width: 194px;
            height: 33px;
            cursor: pointer;
            margin: 7px 0;
            display: block;
            outline: none;

            @media (min-width: 576px) { // RED (SM)
                
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)
                
            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            &.col {
                background-position: 0 0;
            }

            &.exp {
                background-position: 0 -33px;
            }
        }

        & .btn_close {
            position: absolute;
            background: linear-gradient(to bottom,  rgba(113,199,211,1) 0%,rgba(88,131,182,1) 100%);
            width: 48px;
            height: 48px;
            right: -9px;
            top: -9px;
            border: none;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & .rec {
                position: absolute;
                background: white;
                width: 5px;
                height: 28px;
            }

            & .rec:nth-child(1) {
                transform: rotate(-45deg);
            }

            & .rec:nth-child(2) {
                transform: rotate(45deg);
            }
        }
    }
</style>