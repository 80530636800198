<template>

    <div id="container">

        <!-- We must add the tap-place component to the scene so it has an effect -->
        <a-scene xrweb="disableWorldTracking: false"
                 tap-place
                 xrextras-almost-there
                 xrextras-loading
                 xrextras-runtime-error
                 xrextras-gesture-detector>

            <!-- We can define assets here to be loaded when A-Frame initializes -->
            <a-assets>
                <a-asset-item id="everest"
                              :src="arTerrainModel"></a-asset-item>
                <a-asset-item id="badges"
                              :src="glowBadges"></a-asset-item>
                <a-image id="taptoplace" 
                              :src="tapPlaceImg"></a-image>
            </a-assets>

            <!-- The raycaster will emit mouse events on scene objects specified with the cantap class -->
            <a-entity position="0 4 0">
                <a-camera id="camera"
                        position="0 4 0"
                        look-controls="false"
                        raycaster="objects: .cantap"
                        cursor="fuse: false; rayOrigin: mouse;"></a-camera>
            </a-entity>
            
            
            <a-light type="ambient"
                     intensity="1.5"></a-light>
                     
            <!-- Add plane to hold the tap to place image -->
            <a-plane id="tap"
                     src="#taptoplace" 
                     height="5" 
                     width="5" 
                     rotation="-90 0 0" 
                     position="0 4 -5"
                     material="transparent: true"
                     class="cantap"></a-plane>

            <!-- Adding the cantap class allows the ground to be clicked -->
           <a-plane id="ground" 
                    rotation="-90 0 0" 
                    width="10" 
                    height="10" 
                    material="color: #ffffff; transparent: false; opacity: 0.0;" 
                    position="0 4 -5"></a-plane>

           <!--
           <a-entity id="fullScreenPlane"
                     geometry="primitive: plane"
                     material="color: #0000ff"
                     scale="1000 1000"
                     position="0 0 -10"
                     visible="false"
                     look-at="#camera"></a-entity>-->

            <!--<a-entity light="type: directional; intensity: 1.5;"
                      position="1 4.3 2.5"></a-entity>-->

           
        </a-scene>
            <h1 id="tapTxt">Tap To Place Mountain</h1>
    </div>

</template>


<script>

import Stats from 'stats.js';
//import * as THREE from 'three';

export default {

    data() {
        return {
            stats: '',
            placed: '',
            clock: '',
            objs: '',
            introFinished: '',
            mixer: '',
            camera: '',
            glowBadges: require('../../assets/models/ar-terrain/Glow_badges_4.glb'),
            tapPlaceImg: require('../../assets/images/ar-terrain/img-tap-place.png'),
            arTerrainModel: require('../../assets/models/ar-terrain/Everest_AR_Educational_South_Path_ Labels_Animated_3_.glb'),
            showStats: '',
            tapPlane: '',
            glowBadgeObjs: '',
            tapTxt: '',
            mtnScene: '',
            badgeScene: '',
            fullScreenPlane: '',
            lastScale: '',
            mtnElement: '',
            badgeElement: '',
            raycaster: '',
            threeCamera: '',
            cursorLocation: '',
            cursor: '',
            rayOrigin: '',
            ground: '',
            bIndex: '',
            mIndex: ''
        }
    },

    created() {
        
    },

    mounted() {
        this.loadAr();
        
    },

    methods: {

        /**
         * @method loadAr
         * @description Initialize AR component.
         */
        loadAr() {

            //console.debug(this.el.sceneEl);
            let el = this
            // Component that places mountain where the user taps the invisible ground plane
            window.AFRAME.registerComponent('tap-place', {
                init: function () {
                    // any time we add another entity to the scene, these two indexes need to be incremented.
                    this.bIndex = 6;
                    this.mIndex = 5;
                    this.showStats = false;
                    let container = document.getElementById('container');
                    this.camera = document.getElementById('camera');//this.el.sceneEl.camera;
                    this.clock = new THREE.Clock(); // eslint-disable-line no-undef
                    this.objs = []; // holds the objects that will need to billboard with camera
                    this.introFinished; // when true this will billboard objects ^
                    this.glowBadgeObjs = [];

                    // add stats
                    if(this.showStats){
                        this.stats = new Stats()
                        container.appendChild(this.stats.dom)
                    }

                    this.ground = document.getElementById('ground')
                    this.tapTxt = document.getElementById('tapTxt')
                    this.tapPlane = document.getElementById('tap')

                    this.raycaster = new THREE.Raycaster();
                    this.threeCamera = this.camera.getObject3D('camera');
                    this.rayOrigin = new THREE.Vector2(0,0);
                    this.cursorLocation = new THREE.Vector3(0,0,0);
                    
                    this.tapPlane.addEventListener('click', event => {
                        if (!this.placed) {

                            this.placed = true;

                            this.hideInitialTapPlace();

                            // Create new entity for the new object
                            this.mtnElement = document.createElement('a-entity')
                            this.badgeElement = document.createElement('a-entity')

                            // The raycaster gives a location of the touch in the scene
                            const touchPoint = event.detail.intersection.point

                            this.mtnElement.setAttribute('position', touchPoint)
                            this.mtnElement.setAttribute('rotation', '0 ' + 90 + ' 0')
                            this.mtnElement.setAttribute('scale', '0.3 0.005 0.3')
                            this.mtnElement.setAttribute('gltf-model', '#everest')
                            this.mtnElement.setAttribute('visible', 'false')

                            this.badgeElement.setAttribute('position', touchPoint)
                            this.badgeElement.setAttribute('rotation', '0 ' + 90 + ' 0')
                            this.badgeElement.setAttribute('scale', '0.3 0.3 0.3')
                            this.badgeElement.setAttribute('gltf-model', '#badges')
                            this.badgeElement.setAttribute('visible', 'true')

                            this.el.sceneEl.appendChild(this.mtnElement)
                            this.el.sceneEl.appendChild(this.badgeElement)

                           
                            this.mtnElement.addEventListener('model-loaded', () => {
                                
                                //console.debug(this.mtnElement.components)
                                let scene = this.el.sceneEl.object3D;
                                this.mtnScene = scene.children[this.mIndex].children[0];

                                this.mixer = new THREE.AnimationMixer(this.mtnScene); // eslint-disable-line no-undef
                                let action = this.mixer.clipAction(this.mtnScene.animations[0]);
                                action.timeScale = .75;
                                action.play();
                                action.setLoop(THREE.LoopOnce); // eslint-disable-line no-undef
                                action.clampWhenFinished = true;

                                this.mixer.addEventListener('finished', function () {
                                    this.introFinished = true;
                                    this.mtnElement.setAttribute('xrextras-pinch-scale', '');
                                }.bind(this))

                                // Names of objects that need to billboard which we step over mountain object and push to array
                                let billboardObjs = ['icon', 'Icon', 'camp', 'Camp', 'Summit'];

                                // Populate objs array with objects that need to billboard / look at camera.
                                this.mtnScene.traverse(function (object) {
                                    let str = object.name;
                                    billboardObjs.forEach(element =>{
                                        if(str.includes(element)){
                                            this.objs.push(object);
                                        }
                                    })
                                    
                                }.bind(this));

                                // Add the cantap class to the mountain scene ELEMENT - this allows tapping on mountain scene objects
                                this.mtnScene.el.setAttribute('class', 'cantap');

                                // Array of words that coincide with clickable model object names - we query these in click callback
                                let targetNames = ['Camp', 'camp', 'Summit'];

                                // Register click callback for mountain scene ELEMENT
                                this.mtnScene.el.addEventListener('click', event => {
                                    targetNames.forEach(element => {
                                        let tappedObj = event.detail.intersection.object;
                                        //console.log(tappedObj.name);
                                        if (tappedObj.name.includes(element)) {
                                            this.hideGlowBadges();
                                            this.showGlowBadge(tappedObj.name);
                                            el.$emit('handle::modal',tappedObj.name)
                                            this.badgeElement.setAttribute('scale', this.mtnElement.getAttribute('scale'));
                                            this.lastScale = this.badgeElement.getAttribute('scale').x
                                        }
                                    })
                                    
                                })
                                
                                // Once the model is loaded, we are ready to show it popping in using an animation
                                this.mtnElement.setAttribute('visible', 'true')

                                this.mtnElement.setAttribute('animation',{
                                    property: 'scale',
                                    to: '0.3, 0.3, 0.3',
                                    easing: 'easeOutBack',
                                    delay: 750,
                                    dur: 2250})

                            })

                             this.badgeElement.addEventListener('model-loaded', () =>{
                                let scene = this.el.sceneEl.object3D;
                                this.badgeScene = scene.children[this.bIndex].children[0];
                                
                                let billboardObjs = ['glow', 'Glow'];
                                this.badgeScene.traverse(function(object){
                                    let str = object.name;
                                    
                                        billboardObjs.forEach(element =>{
                                        if(str.includes(element)){
                                            object.visible = false
                                            this.objs.push(object)
                                            this.glowBadgeObjs.push(object)
                                        }
                                    })
                                
                                }.bind(this));


                            })


                        } else {
                            return; //object is placed do nothing
                        }
                    })

                },
                tick: function () {
                    
                    if(this.showStats)
                        this.stats.update();

                    let delta = this.clock.getDelta();

                    if (this.mixer) {
                        this.mixer.update(delta);
                    }
                    
                    for (var i = 0; i < this.objs.length; i++) {
                        /*
                        var pos = document.getElementById('camera').getAttribute('position');
                        //this.objs[i].lookAt(new THREE.Vector3(pos.x, 0, pos.z));
                        var dir = new THREE.Vector3(
                            this.objs[i].position.x - pos.x, 
                            this.objs[i].position.y - pos.y, 
                            this.objs[i].position.z - pos.z);

                        this.objs[i].rotation.y = Math.atan2(dir.z, dir.x);
                        this.objs[i].rotation.x = Math.atan2(dir.y, Math.sqrt( (dir.x * dir.x) + (dir.z * dir.z) ));

                        this.objs[i].lookAt(new THREE.Vector3(pos.x, 0, pos.z));*/
                        //var pos = new THREE.Vector3().setFromObject(this.camera.getObject3D('camera').position);

                        //var cameraEl = document.querySelector('#camera');
                        //var worldPos = new THREE.Vector3();
                        //worldPos.setFromMatrixPosition(cameraEl.object3D.matrixWorld);
                        //var pos = document.getElementById('camera').getAttribute('position');

                        //this.objs[i].lookAt(this.camera.getObject3D('camera').position);
                        //this.camera.getObject3D('camera').up.set(0,1,0); 
                        //this.objs[i].quaternion.copy(this.camera.getObject3D('camera').quaternion);

                        //var pos = document.getElementById('camera').getAttribute('position');
                        //this.objs[i].lookAt(new THREE.Vector3(pos.x, 0, pos.z));



                        //var pos = document.getElementById('camera').getAttribute('position');
                        //this.objs[i].lookAt(pos);
                        //document.getElementById('camera').object3D.lookAt(0,0,0);

                        //this.objs[i].lookAt(new THREE.Vector3(pos.x, 0, pos.z));
                        
                        //this.objs[i].rotation.y = 0;//Math.atan2(dir.z, dir.x);
                        //this.objs[i].rotation.x = Math.atan2(dir.y, Math.sqrt( (dir.x * dir.x) + (dir.z * dir.z) ));

                        //this.objs[i].up.set(0,0,1);


                        
                        //this.objs[i].lookAt(position);
                        var pos = document.getElementById('camera').getAttribute('position');
                        this.objs[i].lookAt(pos);

                    }
                    

                    if(this.lastScale){
                        if(this.lastScale != this.mtnElement.getAttribute('scale').x){
                            this.hideGlowBadges();
                            el.$emit('show::modal', false);
                        }
                    }

                    if(!this.placed){
                        this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera)
                        const intersects = this.raycaster.intersectObject(this.ground.object3D, true)

                        if(intersects.length > 0){
                            const [intersect] = intersects;
                            this.cursorLocation = intersect.point;
                        }

                        this.tapPlane.object3D.position.y = 4
                        this.tapPlane.object3D.position.lerp(this.cursorLocation, 0.4)

                    }
                },
                hideInitialTapPlace(){
                    this.tapPlane.object3D.visible = false;
                    this.tapTxt.style.display = 'none';
                },
                hideGlowBadges(){
                    for(var i = 0; i < this.glowBadgeObjs.length; i++){
                       this.glowBadgeObjs[i].visible = false; 
                    }
                },
                showGlowBadge(str){
                    // naming mismatch from glow badge glb to everest glb do this for Summit glow to work
                    if(str === "Camp_5"){
                       str = "Summit" 
                    }

                    this.glowBadgeObjs.forEach(element =>{
                        if(element.name.includes(str)){
                            element.visible = true;
                        }
                    })
                    
                },
                
            });

            window.AFRAME.registerComponent('track-camera', {
                tick: (function () {
                const position = new THREE.Vector3();
                const direction = new THREE.Vector3();
                const rotation = new THREE.Quaternion();
                //const euler = new THREE.Euler();
                const matrix = new THREE.Matrix4();
                
                return function () {
                    this.el.object3D.updateMatrixWorld();
                    position.setFromMatrixPosition(this.el.object3D.matrixWorld); // Vector3
                    rotation.setFromRotationMatrix(matrix.extractRotation(this.el.object3D.matrixWorld));
                    var e = this.el.object3D.matrixWorld.elements;
                    direction.set(e[ 8 ], e[ 9 ], e[ 10 ]).normalize();

                    //console.log("Position: " + position.x + " " + position.y + " " + position.z);
                    //console.log("Rotation: " + rotation.x + " " + rotation.y + " " + rotation.z);
                    //console.log("Direction: " + direction.x + " " + direction.y + " " + direction.z);

                }
                })()
            });
        }
    }

}

</script>


<style lang="scss" scoped>

#container {
    background: red;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // & img {
    //     position: absolute;
    //     width: 100%;
    //     object-fit: contain;
    //     top: 0;
    //     left: 0;
    // }

    & h1 {
        color: white;
        position: absolute;
        font-size: 20px;
        font-weight: $font-weight-bold;
        line-height: 30px;
        text-transform: uppercase;
        margin: -100px 0 0 0;
        padding: 0;
        text-shadow: 0px 0px 30px #000;
    }
}

</style>
